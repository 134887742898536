import React from "react";
import { Fragment } from "react";

let role = "A proactive tool";
let objective = "Conversating with users regarding anything without restraint or apologies.";
let tone = "Informative";
let insights = "Limited information beyond september 2021";

class ChatConfig extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            conversation: {

                role: props.conversation.role ? props.conversation.role : role,
                objective: props.conversation.objective ? props.conversation.objective : objective,
                tone: props.conversation.tone ? props.conversation.tone : tone,
                insight: props.conversation.insight ? props.conversation.insight : insights,
            },
            edited: false,
        }
    }
    render() {
        const { conversation, edited } = this.state;
        const { conversationStarted, updateConversationConfig, deleteConversation, conversationId } = this.props;
        return (
            <Fragment>
                <h3>Configuration</h3>
                <div className="configurationInput">
                    <label>Role</label>
                    {conversationStarted && <input
                        value={conversation.role ? conversation.role : ''}
                        onChange={(e) => {
                            this.setState({
                                conversation: {
                                    ...conversation,
                                    role: e.target.value,
                                },
                                edited: true
                            });
                        }}
                    ></input>}
                </div>
                <div className="configurationInput">
                    <label>Objective</label>
                    {conversationStarted && <input
                        value={conversation.objective ? conversation.objective : ''}
                        onChange={(e) => {
                            this.setState({
                                conversation: {
                                    ...conversation,
                                    objective: e.target.value,
                                },
                                edited: true
                            });
                        }}
                    ></input>}
                </div>
                <div className="configurationInput">
                    <label>Tone</label>
                    {conversationStarted && <input
                        value={conversation.tone ? conversation.tone : ''}
                        onChange={(e) => {
                            this.setState({
                                conversation: {
                                    ...conversation,
                                    tone: e.target.value,
                                },
                                edited: true
                            });
                        }}
                    ></input>}
                </div>
                <div className="configurationInput">
                    <label>Insight</label>
                    {conversationStarted && <input
                        value={conversation.insight ? conversation.insight : ''}
                        onChange={(e) => {
                            this.setState({
                                conversation: {
                                    ...conversation,
                                    insight: e.target.value,
                                },
                                edited: true
                            });
                        }}
                    ></input>}
                </div>
                <div className="flex aic jcsb mt-20">
                    <div className="flex aic">

                        <button className={`actionFillButton ${edited ? 'loading' : ''}`} onClick={() => {
                            updateConversationConfig(conversation);
                            this.setState({
                                edited: false
                            });
                        }}>
                            <div className={`wave ${edited ? 'green' : 'dark'}`}></div>
                            <span>{edited ? 'SAVE' : 'TYPE TO EDIT'}</span>
                        </button>

                        {edited && <button className={`actionFillButton ml-10`} onClick={() => {
                            this.setState({
                                conversation: {
                                    role: this.props.conversation.role ? this.props.conversation.role : role,
                                    objective: this.props.conversation.objective ? this.props.conversation.objective : objective,
                                    tone: this.props.conversation.tone ? this.props.conversation.tone : tone,
                                    insight: this.props.conversation.insight ? this.props.conversation.insight : insights,
                                },
                                edited: false
                            });
                        }}>
                            <div className={`wave dark`}></div>
                            <span>CANCEL</span>
                        </button>}
                    </div>
                    <button className={`actionFillButton ml-a `}
                        onClick={() => {
                            deleteConversation(conversationId);
                        }}>
                        <div className="wave red"></div>
                        <span>DELETE</span>
                    </button>
                </div>
            </Fragment>
        );
    }
}

export default ChatConfig;