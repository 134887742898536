import React from "react";

import { gunAvatar } from 'gun-avatar'

const pub =
    "YZOBPSkw75Ute2tFhdjDQgzR-GsGhlfSlZxgEZKuquI.2F-j9ItJY44U8vcRAsj-5lxnECG5TDyuPD8gEiuInp8";
let barInterval;
class ChatLoader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bars: [""]
        }
    }

    componentDidMount() {
        barInterval = setInterval(() => {
            let { bars } = this.state;
            bars.push("")
            this.setState({ bars: bars }, this.props.scrollToBottom);
        }, 1000);
    }

    render() {
        const { bars } = this.state;
        return <li className="replies">

            <div className="message-container">
                <div className="circle"><img src={gunAvatar({ pub, size: 200, draw: 'squares' })} /></div>
                <div className="textWrapper">
                    {bars.map((bar, i) => <div className="textBar" key={i}></div>)}</div>
            </div>
        </li>
    }
}

export default ChatLoader;