import React from "react";

class ChatInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: ""
        }
    }

    sendMessage = () => {
        const { conversationStarted, conversate, startConversation } = this.props;
        const { message } = this.state;
        this.setState({ message: "" });
        if (conversationStarted) conversate(message);
        else startConversation(message);
    }

    render() {
        const { waiting } = this.props;
        const { message } = this.state;

        const messageBreaks = message.split("\n").length - 1;
        return <div className="message-input">
            <div className="wrap input-container">
                <textarea
                    type="text"
                    placeholder="Write your message..."
                    value={message}
                    onChange={(event) => {
                        this.setState({ message: event.target.value });
                        this.props.untoggleTopMenu();
                    }}
                    onKeyUp={(e) => {
                        //Enter key
                        if (e.key === "Enter" && !e.shiftKey) {
                            this.sendMessage();
                        }
                    }}

                    onKeyDown={(e) => {
                        //Enter key
                        if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                        }
                    }}
                    style={{ height: 40 + 20 * messageBreaks }}
                />
                {/* <i className="fa fa-paperclip attachment" aria-hidden="true"></i> */}
                <button disabled={waiting} onClick={this.sendMessage} className="submit">
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    }
}

export default ChatInput;