import React from "react";
import "../assets/css/landing.scss";

class Privacy extends React.Component {

    render() {
        const { open, toggle, connect } = this.props;
        return <div className={`landing ${open ? 'open' : ''}`}>
            <header style={{ position: 'fixed', width: '100%' }}>
                <i className="fa fa-times ml-a" onClick={toggle}></i>
            </header>

            <main>
                <section className="intro">
                    <h1 style={{ marginTop: 30 }}>Privacy Policy</h1>
                    <div className="intro-content">

                        <p className="lg-p mt-2" style={{ textAlign: 'left' }}>
                            1. Data Collection
                            <br />
                            GPT Master does not collect any conversation data, user-generated content, or any other data that is not essential for the app's functionality. Usage data is collected solely for the purpose of improving the app's functionality and is stored on the user's device.
                            <br /><br />
                            2. Data Use
                            <br />
                            GPT Master uses the limited data collected to provide users with personalized recommendations and optimize app performance. The data is never shared with third parties unless required by law. It is not used for advertising, marketing purposes, or user profiling.
                            <br /><br />
                            3. Data Storage
                            <br />
                            All user data collected by GPT Master is stored securely on the user's device. This ensures that the user has complete control over their data and privacy. Reasonable security measures will be taken to protect user data both during transmission and storage.
                            <br /><br />
                            4. User Control
                            <br />
                            The user has the ability to delete their data from GPT Master at any time. The user also has the option to opt-out of data collection and sharing, although doing so may impact app functionality. The user is in complete control of their data, and no data will be retained on the device beyond the user's control.
                            <br /><br />
                            5. Google Analytics
                            <br />
                            GPT Master uses Google Analytics to collect app usage data, including the pages visited and the actions taken. Google Analytics may collect user demographics, interests, and other relevant information. This data is used to improve user experience and app functionality and is stored on the user's device.
                            <br /><br />
                            6. Sentry and Error Logging
                            <br />
                            Sentry is used for error logging to ensure app stability and performance. Sentry collects app logs, including information about any bugs, errors, and issues that occur while using the app. This data is used for troubleshooting. Sentry may also collect user data, including IP addresses.
                            <br /><br />
                            7. Privacy Policy Updates
                            <br />
                            The creator of GPT Master reserves the right to modify this privacy policy at any time, without notice. Users are encouraged to check the policy for updates before using the app.
                            <br /><br />
                            8. Age Restrictions
                            <br />
                            GPT Master is intended for use by individuals who are 18 years of age or older. If the user is under the age of 18, they must use the app with the permission and supervision of a parent or legal guardian.
                        </p>
                    </div>
                </section>

            </main>
            <footer>
                <section className="contact-info" style={{ paddingTop: 20 }}>
                    <div className="contact-info-location">
                        <div className="contact-info-location-item">
                            <img className="img-footer" src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-location.svg" alt="" />
                            <p>California, USA</p>
                        </div>
                        <div className="contact-info-contacts-item">
                            <img className="img-footer" src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-email.svg" alt="" />
                            <p>contact@gptmaster.app</p>
                        </div>
                    </div>
                    <div className="contact-info-social-links">
                        <a className="icon-link" href="#">
                            <div className="contact-info-social-link-item flex-center"><i className="fab fa-facebook-f"></i></div>
                        </a>
                        <a className="icon-link" href="#">
                            <div className="contact-info-social-link-item flex-center"><i className="fab fa-twitter"></i></div>
                        </a>
                        <a className="icon-link" href="#">
                            <div className="contact-info-social-link-item flex-center"><i className="fab fa-instagram"></i></div>
                        </a>
                    </div>
                    <div className="attribution-container">
                        <p className="attribution">&copy; 2023 AI Industry Experts, LLC. All rights reserved.</p>
                    </div>
                </section>
            </footer>
        </div>
    }
}

export default Privacy;