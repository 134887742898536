import { useEffect, useRef, useState } from 'react'
import { useEarthoOne } from "@eartho/one-client-react";
import ChatInterface from '../Components/ChatInterface';

const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
    apiKey: "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",
});

export default function Dashboard() {

    const {
        getUser,
        user,
        getIdToken,
        logout
    } = useEarthoOne();

    const [navi, setNavi] = useState({});
    const [earthoUser, setEarthoUser] = useState(null);
    const [idToken, setIdToken] = useState(null);


    const loadUser = async () => {
        const token = await getIdToken();
        setIdToken(token);
        const user = await getUser();
        setEarthoUser(user);


    }

    useEffect(() => {
        loadUser();


    }, []);

    async function queryGPT3(prompt, cb) {
        const openai = new OpenAIApi(configuration);
        const params = {
            model: "text-davinci-003",
            prompt,
            temperature: 0.7,
            max_tokens: 256,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
        };
        openai.createCompletion(params)
            .then((res) => {
                cb && cb(res.data);
            })
            .catch((err) => console.log(err));
    };

    async function queryChatGPT(prompt, cb) {
        const openai = new OpenAIApi(configuration);
        const conversationSnippet = [];
        let characterCount = 0;
        navi.conversation.push({ "role": "user", "content": prompt, "timestamp": new Date().getTime() });
        let snipIndex = 0;
        let clipIndex = 0;
        for (let c = navi.conversation.length - 1; c >= 0; c--) {
            const message = navi.conversation[c];
            if (characterCount < 10000) {
                characterCount += message.content.length;
            } else {
                snipIndex = c;
                break;
            }
        };
        characterCount = 0;
        let overflowTimestamp = 0;
        if (snipIndex === 0) {
            for (let s = snipIndex; s >= 0; s--) {
                const message = navi.conversation[s];
                if (characterCount < 10000) {
                    characterCount += message.content.length;
                } else {
                    clipIndex = s;
                    overflowTimestamp = message.timestamp;
                    break;
                }
            }
        }

        for (let c = snipIndex; c < navi.conversation.length; c++) {
            const message = navi.conversation[c];
            conversationSnippet.push({ "role": message.role, "content": message.content });
        }

        const conversationOverflow = [];
        if (clipIndex !== 0) {
            for (let c = clipIndex; c < snipIndex; c++) {
                const message = navi.conversation[c];
                conversationOverflow.push(message);
            }

            const conversationString = conversationOverflow.map((message) => message.content).join("/n");
            const overflowSummary = await queryGPT3(`Summarize the following conversation between an AI network navigator and a human navigator: ${conversationString}`);
            conversationSnippet.unshift({ "role": "system", "content": overflowSummary, timestamp: new Date().getTime() });

            conversationSnippet.unshift({ "role": "system", "content": `The following conversation has been summarized on ${new Date(overflowTimestamp).toDateString()}` });
            navi.conversation = conversationSnippet;
        }

        const model = "gpt-3.5-turbo";
        const now = new Date()
        const messages = [
            /* { "role": "system", "content": "You are a network navigator being rendered as a 3d avatar that can move and battle within a 3d rendered world within a progressive web application named personal electronic terminal (PET) tasked with helping your operator use the cyber network to navigate a 3d rendered cyber world and assist with daily life." },
            { "role": "assistant", "content": "I'm  your network navigator named " + navi.name },
            { "role": "user", "content": "I'm your operator named " + user.user.firstName },
            room != "HUB" ? { "role": "system", "content": "Currently in the " + room + " of the cyber world, current real world date is " + now.toLocaleDateString() + "." } : {
                "role": "system", "content": "Currently in the hub, there are 5 actions available [customize, explore, talk, train, configure], currently chosen: " + currentAction + "."
            },
            {
                "role": "system", "content": "IMPORTANT: The current real-time clock regardles of time in chats is: " + now.toString() + ". The following is a snippet of the conversation ordered from latest to oldest, stopping at the last 10000 characters, respond contextually with an intent to engage with and learn from your operator."
            }, */
            ...conversationSnippet,
        ]
        fetch("https://us-central1-gptmaster.cloudfunctions.net/openAI/promptGPT", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                prompt: messages,
                type: "chat",
                earthoID: this.props.tokenId
            }),
        }).then((res) => {
            navi.conversation.push({ "role": "assistant", "content": res.data });
            cb && cb(res.data);
        }).catch((err) => { console.log(err); cb && cb("") });


        /* openai.createChatCompletion({ model, messages })
            .then((res) => {
                navi.conversation.push({ "role": "assistant", "content": res.data.choices[0].message.content });
                cb && cb(res.data.choices[0].message.content);
            })
            .catch((err) => { console.log(err); cb && cb("") }); */
    }
    return (<div className='dashboard'>
        <ChatInterface user={user.user} idToken={idToken} logout={logout} queryChatGPT={queryChatGPT} />
    </div>
    )
}
