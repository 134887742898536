import React from "react";
import "../assets/css/landing.scss";

class Landing extends React.Component {

    render() {
        const { open, toggle, connect } = this.props;
        return <div className={`landing ${open ? 'open' : ''}`}>
            <header style={{ position: 'fixed', width: '100%' }}>
                <i className="fa fa-times ml-a" onClick={toggle}></i>
            </header>

            <main>
                <section className="intro">
                    <h1 style={{ marginBottom: 50, marginTop: 30 }}>Unleash Your Creativity and Achieve More with Generative AI</h1>
                    <div className="intro-img">
                        <img src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/illustration-intro.png" alt="illustration-intro" />
                    </div>
                    <div className="intro-content">

                        <p className="lg-p mt-2">
                            GPT Master is the ultimate tool for professionals seeking to unlock unlimited creative possibilities. <br />Featuring powerful generative AI technology and
                            a rich library of ready-to-use templates, GPT Master makes it easy for you to formalize and execute your ideas, streamline communication, and achieve better
                            outcomes. With our advanced conversational tools, you can craft compelling custom content in no time, enhancing your unique brand voice.
                        </p>

                        <button className="btn mt-20" onClick={connect}>CONNECT</button>
                    </div>
                </section>
                <section className="features">

                    <div className="features-item">
                        <div className="features-item-head">
                            <img src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-collaboration.svg" alt="" />
                        </div>
                        <div className="features-item-body mt-2">
                            <h3>Generative AI</h3>

                            <p>
                                GPT Master is powered by advanced generative AI technology that enables speedy brainstorming and execution of your ideas. It allows you to craft
                                high-quality, engaging content that resonates with your target audience within seconds.
                            </p>
                        </div>
                    </div>

                    <div className="features-item">
                        <div className="features-item-head">
                            <img src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-any-file.svg" alt="" />
                        </div>
                        <div className="features-item-body mt-2">
                            <h3>Advanced Conversational Tools</h3>
                            <p>You will have access to advanced conversational tools that allow you to effortlessly articulate your ideas and execute on them seamlessly. Through conversation specific briefs, notes, bookmarks, and configurations,s context will be easily accessible and managable.</p>
                        </div>
                    </div>
                    <div className="features-item">
                        <div className="features-item-head">
                            <img src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-security.svg" alt="" />
                        </div>
                        <div className="features-item-body mt-2">
                            <h3>Decentralized Security</h3>
                            <p>GPT Master is built to be private and secure, all conversations are encrypted and stored on user authorized devices putting you in control of your data with peace of mind that it will not be used to training or targeting.</p>
                        </div>
                    </div>
                    <div className="features-item">
                        <div className="features-item-head">
                            <img src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-access-anywhere.svg" alt="" />
                        </div>
                        <div className="features-item-body mt-2">
                            <h3>User-friendly interface</h3>
                            <p>Our user-friendly interface makes GPT Master easy to use, even for beginners who lack technical expertise. Whether you're at work or on the go, access the power of GPT master on any device to keep you productive all the time.</p>
                        </div>
                    </div>
                </section>
                <section className="productive">
                    <div className="productive-img">
                        <img src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/illustration-stay-productive.png" alt="" />
                    </div>

                    <div className="productive-content">
                        <h2>Supercharge Your Conversations with GPT Master</h2>

                        <p className="lg-p">
                            <strong style={{ fontSize: "80%", textTransform: "uppercase" }}>Strategic Plans and Business Strategies</strong><br />
                            GPT Master provides you with the tools you need to create and execute effective strategic plans and business strategies.
                        </p>

                        <p className="lg-p">
                            <strong style={{ fontSize: "80%", textTransform: "uppercase" }}>Custom Content for Brands</strong><br />
                            You can create unique and compelling content that adheres to your brand's overall tone and style with GPT Master.
                        </p>
                        <p className="lg-p">
                            <strong style={{ fontSize: "80%", textTransform: "uppercase" }}>Creative Writing</strong><br />
                            GPT Master is also the perfect tool for unleashing your creativity. You can use it to create custom generative content pieces such as lesson plans, stories,
                            songs, and more.
                        </p>
                    </div>
                </section>

                {/* <section className="testimonial">
                    <div className="testimonial-item">
                        <div className="testimonial-item-text">
                            <p>Fylo has improved our team productivity by an order of magnitude. Since making the switch our team has become a well-oiled collaboration machine.</p>
                        </div>
                        <div className="testimonial-item-reviewer">
                            <div className="testimonial-item-reviewer-img">
                                <img src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/profile-1.jpg" alt="" />
                            </div>
                            <div className="ml-1 testimonial-item-reviewer-text">
                                <h4>Satish Patel</h4>
                                <p>Founder & CEO, Huddle</p>
                            </div>
                        </div>
                    </div>

                    <div className="testimonial-item">
                        <div className="testimonial-item-text">
                            <p>Fylo has improved our team productivity by an order of magnitude. Since making the switch our team has become a well-oiled collaboration machine.</p>
                        </div>
                        <div className="testimonial-item-reviewer">
                            <div className="testimonial-item-reviewer-img">
                                <img src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/profile-2.jpg" alt="" />
                            </div>
                            <div className="ml-1 testimonial-item-reviewer-text">
                                <h4>Bruce McKenzie</h4>
                                <p>Founder & CEO, Huddle</p>
                            </div>
                        </div>
                    </div>

                    <div className="testimonial-item">
                        <div className="testimonial-item-text">
                            <p>Fylo has improved our team productivity by an order of magnitude. Since making the switch our team has become a well-oiled collaboration machine.</p>
                        </div>
                        <div className="testimonial-item-reviewer">
                            <div className="testimonial-item-reviewer-img">
                                <img src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/profile-3.jpg" alt="" />
                            </div>
                            <div className="ml-1 testimonial-item-reviewer-text">
                                <h4>Iva Boyd</h4>
                                <p>Founder & CEO, Huddle</p>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="cta">
                    <div className="cta-item">
                        <div className="cta-text">
                            <h2>Launch GPT Master Now to Unleash Your Creative Potential</h2>
                            <p><i>Disclaimer: The content and layout for this site was generated using GPT Master</i></p>
                        </div>
                        <div className="cta-form mt-2" action="">
                            <button className="btn-block" onClick={connect}>START FOR FREE</button>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <section className="contact-info">
                    <div className="contact-info-location">
                        <div className="contact-info-location-item">
                            <img className="img-footer" src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-location.svg" alt="" />
                            <p>California, USA</p>
                        </div>
                        <div className="contact-info-contacts-item">
                            <img className="img-footer" src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-email.svg" alt="" />
                            <p>contact@gptmaster.app</p>
                        </div>
                    </div>
                    <div className="contact-info-social-links">
                        <a className="icon-link" href="#">
                            <div className="contact-info-social-link-item flex-center"><i className="fab fa-facebook-f"></i></div>
                        </a>
                        <a className="icon-link" href="#">
                            <div className="contact-info-social-link-item flex-center"><i className="fab fa-twitter"></i></div>
                        </a>
                        <a className="icon-link" href="#">
                            <div className="contact-info-social-link-item flex-center"><i className="fab fa-instagram"></i></div>
                        </a>
                    </div>
                    <div className="attribution-container">
                        <p className="attribution">&copy; 2023 AI Industry Experts, LLC. All rights reserved.</p>
                    </div>
                </section>
            </footer>
        </div>
    }
}

export default Landing;