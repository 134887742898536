import React from "react";
import "../assets/css/landing.scss";

class Terms extends React.Component {

    render() {
        const { open, toggle, connect } = this.props;
        return <div className={`landing ${open ? 'open' : ''}`}>
            <header style={{ position: 'fixed', width: '100%' }}>
                <i className="fa fa-times ml-a" onClick={toggle}></i>
            </header>

            <main>
                <section className="intro">
                    <h1 style={{ marginTop: 30 }}>Terms of Use</h1>
                    <div className="intro-content">

                        <p className="lg-p mt-2" style={{ textAlign: 'left' }}>
                            1. Acceptance of Terms
                            <br />
                            By using GPT Master, you agree to the following terms and conditions. If you do not agree with these terms, please do not use the app.
                            <br /><br />
                            2. Intellectual Property
                            <br />

                            All intellectual property rights, including copyrights, trademarks, and patents, to GPT Master belong to the creator. You may not use any content or information from GPT Master without explicit permission from the creator.
                            <br /><br />

                            3. Limitations of Liability
                            <br />

                            The creator of GPT Master is not responsible for any damages or losses incurred as a result of using the app. The user assumes all responsibility for any actions taken or decisions made while using GPT Master.
                            <br /><br />

                            4. User Data
                            <br />

                            The user retains all ownership rights to any data entered into GPT Master. The creator of the app will take reasonable precautions to protect user data, but cannot guarantee the security of this data.
                            <br /><br />

                            5. Privacy Policy
                            <br />

                            The creator of GPT Master will not sell or share user data with any third parties, except as required by law.
                            <br /><br />

                            6. Modifications to Terms
                            <br />

                            The creator of GPT Master reserves the right to modify these terms at any time, without notice. It is the responsibility of the user to check for updates to these terms before using the app.
                            <br /><br />

                            7. Governing Law
                            <br />

                            These terms and conditions are governed by the laws of the jurisdiction in which the creator of GPT Master is located.

                        </p>
                    </div>
                </section>

            </main>
            <footer>
                <section className="contact-info" style={{ paddingTop: 20 }}>
                    <div className="contact-info-location">
                        <div className="contact-info-location-item">
                            <img className="img-footer" src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-location.svg" alt="" />
                            <p>California, USA</p>
                        </div>
                        <div className="contact-info-contacts-item">
                            <img className="img-footer" src="https://hannahshiels.github.io/front-end-mentor/fylo-dark-theme-landing-page-master/images/icon-email.svg" alt="" />
                            <p>contact@gptmaster.app</p>
                        </div>
                    </div>
                    <div className="contact-info-social-links">
                        <a className="icon-link" href="#">
                            <div className="contact-info-social-link-item flex-center"><i className="fab fa-facebook-f"></i></div>
                        </a>
                        <a className="icon-link" href="#">
                            <div className="contact-info-social-link-item flex-center"><i className="fab fa-twitter"></i></div>
                        </a>
                        <a className="icon-link" href="#">
                            <div className="contact-info-social-link-item flex-center"><i className="fab fa-instagram"></i></div>
                        </a>
                    </div>
                    <div className="attribution-container">
                        <p className="attribution">&copy; 2023 AI Industry Experts, LLC. All rights reserved.</p>
                    </div>
                </section>
            </footer>
        </div>
    }
}

export default Terms;