import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import reportWebVitals from './reportWebVitals';

import { EarthoOneProvider } from '@eartho/one-client-react';

if (navigator.storage && navigator.storage.persist) {
  navigator.storage.persist().then(persistent => {
    if (persistent) {
      console.log("Storage will not be cleared except by explicit user action");
    } else {
      console.warn("Storage may be cleared by the UA under storage pressure.");
    }
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <EarthoOneProvider clientId='mTtkja2Dkh6HlfZDxT15' enabledProviders={['facebook', 'google', 'apple', 'github']} >
    <App />
  </EarthoOneProvider>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
