import React, { Fragment } from "react";
import Message from "./Message";
import { gunAvatar } from "gun-avatar";
import ChatLoader from "./ChatLoader";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import ChatInput from "./ChatInput";
import ChatConfig from "./ChatConfig";

let role = "A proactive tool";
let objective = "Conversating with user regarding anything without restraint or apologies.";
let tone = "Informative";
let insights = "Limited information beyond september 2021";

class ChatRoom extends React.Component {
    constructor(props) {
        super(props);
        this.messageRef = React.createRef();
        this.state = {
            config: {
                role: role,
                objective: objective,
                tone: tone,
                insights: insights,
            },
            configLoaded: false,
            conversationKey: "",
            workshopOpen: false,
            pluginsOpen: false,
            configurationOpen: false,
            messages: {},
            message: "",
            waiting: false,
            gptTags: {},
            bookmarks: {},
            running: false,
            generating: false,
            gptTools: [
                {
                    name: "Idea GPT",
                    prompt: "Generate ideas based on the conversation so far.",
                    template: "1. Idea 1\n2. Idea 2\n 3. Idea 3\n",
                    tags: ["Idea", "Business", "Writing", "Creative"], // used to filter the list of tools
                },
                {
                    name: "Chat GPT",
                    prompt: "Generate a response based on the conversation so far.",
                    template:
                        "You: Hello\nBot: Hi, how are you?\nYou: I'm good, how are you?\nBot: I'm good, thanks for asking.\nYou: That's good to hear.\nBot: Yes, it is.\nYou: What are you up to?\nBot: I'm just hanging",
                    tags: ["Collaboration"], // used to filter the list of tools
                },
                {
                    name: "Story GPT",
                    prompt: "Generate a story based on the conversation so far.",
                    template: "Introduction\nRising Action\nClimax\nFalling Action\nResolution\n",
                    tags: ["Writing"], // used to filter the list of tools
                },
                {
                    name: "Poem GPT",
                    prompt: "Generate a poem based on the conversation so far.",
                    template: "# Title of Poem\n## Stanza 1\nLine 1 of Stanza 1\nLine 2 of Stanza 1\nLine 3 of Stanza 1\n\n## Stanza 2\nLine 1 of Stanza 2\nLine 2 of Stanza 2\nLine 3 of Stanza 2\n\n## Stanza 3\nLine 1 of Stanza 3\nLine 2 of Stanza 3\nLine 3 of Stanza 3",
                    tags: ["Writing"], // used to filter the list of tools
                },
                {
                    name: "Javascript GPT",
                    prompt: "Generate javascript code based on the conversation so far.",
                    template: "function ideaFromConversation(paramsFromConv) {\nconst objective = performObjective(paramsFromConv);\treturn objective;\n}",
                    tags: ["Javascript", "Coding"], // used to filter the list of tools
                },
                {
                    name: "Python GPT",
                    prompt: "Generate python code based on the conversation so far.",
                    template: "def ideaFromConversation(paramsFromConv):\n\tobjective = performObjective(paramsFromConv)\n\treturn objective",
                    tags: ["Python", "Coding"], // used to filter the list of tools
                },
                {
                    name: "Big O GPT",
                    prompt: "Generate big O notation based on the conversation so far.",
                    template: "O(n) - Linear\nO(n^2) - Quadratic\nO(n^3) - Cubic\nO(2^n) - Exponential\nO(log n) - Logarithmic\nO(1) - Constant",
                    tags: ["Coding"], // used to filter the list of tools
                },
                {
                    name: "Business Plan GPT",
                    prompt: "Generate a business plan based on the conversation so far.",
                    template:
                        "Executive Summary\nCompany Description\nMarket Analysis\nOrganization & Management\nService or Product Line\nMarketing & Sales\nFunding Request\nFinancial Projections\nAppendix",
                    tags: ["Business", "Idea"], // used to filter the list of tools
                },
                {
                    name: "Marketing Plan GPT",
                    prompt: "Generate a marketing plan based on the conversation so far.",
                    template: "Executive Summary\nSituation Analysis\nTarget Market\nCompetitive Analysis\nMarket Strategy\nBudget\nMetrics",
                    tags: ["Business", "Marketing"], // used to filter the list of tools
                },
                {
                    name: "Corporate Strategy GPT",
                    prompt: "Generate a corporate strategy based on the conversation so far.",
                    template: "Mission\nVision\nValues\nStrategy\nSWOT Analysis\nGoals\nObjectives\nAction Plan",
                    tags: ["Business"], // used to filter the list of tools
                },
                {
                    name: "Product Strategy GPT",
                    prompt: "Generate a product strategy based on the conversation so far.",
                    template: "Vision\nGoals\nTarget Market\nCompetitive Analysis\nProduct Roadmap\nKPIs\nBudget\nMetrics",
                    tags: ["Business"], // used to filter the list of tools
                },
                {
                    name: "Event Plan GPT",
                    prompt: "Generate an event plan based on the conversation so far.",
                    template: "Event Overview\nEvent Objectives\nEvent Theme\nEvent Budget\nEvent Timeline\nEvent Marketing\nEvent Team\nEvent Evaluation",
                    tags: ["ESvent", "Marketing"], // used to filter the list of tools
                },
                {
                    name: "Marketing Campaign GPT",
                    prompt: "Generate a marketing campaign based on the conversation so far.",
                    template:
                        "Campaign Overview\nCampaign Goals\nCampaign Target Audience\nCampaign Budget\nCampaign Timeline\nCampaign Marketing\nCampaign Team\nCampaign Evaluation",
                    tags: ["Marketing"], // used to filter the list of tools
                },
                {
                    name: "Marketing Email GPT",
                    prompt: "Generate a marketing email based on the conversation so far.",
                    template: "Subject Line\nGreeting\nIntroduction\nBody\nCall to Action\nSignature",
                    tags: ["Marketing"], // used to filter the list of tools
                },
                {
                    name: "Blog Post GPT",
                    prompt: "Generate a blog post based on the conversation so far.",
                    template: "Title\nIntroduction\nBody\nConclusion\nCall to Action\nSignature",
                    tags: ["Marketing"], // used to filter the list of tools
                },
                {
                    name: "Job Posting GPT",
                    prompt: "Generate a job posting based on the conversation so far.",
                    template: "Job Title\nJob Description\nJob Requirements\nJob Benefits\nJob Location\nJob Salary\nJob Application Instructions",
                    tags: ["Business"], // used to filter the list of tools
                },
                {
                    name: "Resume GPT",
                    prompt: "Generate a resume based on the conversation so far.",
                    template: "Name\nContact Information\nSummary\nWork Experience\nEducation\nSkills\nAwards\nCertifications\nInterests",
                    tags: ["Professional"], // used to filter the list of tools
                },
                {
                    name: "Cover Letter GPT",
                    prompt: "Generate a cover letter based on the conversation so far.",
                    template: "Contact Information\nSalutation\nIntroduction\nBody\nConclusion\nSignature",
                    tags: ["Professional"], // used to filter the list of tools
                },
                {
                    name: "Letter of Recommendation GPT",
                    prompt: "Generate a letter of recommendation based on the conversation so far.",
                    template: "Introduction\nBody\nConclusion\nSignature",
                    tags: ["Professional"], // used to filter the list of tools
                },
                {
                    name: "Study Guide GPT",
                    prompt: "Generate a study guide based on the conversation so far.",
                    template:
                        "Class\nProfessor\nDate\nTopics\nKey Terms\nKey Concepts\nKey People\nKey Events\nKey Dates\nKey Formulas\nKey Equations\nKey Theories\nKey Experiments\nKey Quotes",
                    tags: ["Education"], // used to filter the list of tools
                },
                {
                    name: "Lesson Plan GPT",
                    prompt: "Generate a lesson plan based on the conversation so far.",
                    template: "Lesson Title\nLesson Objectives\nLesson Overview\nLesson Materials\nLesson Activities\nLesson Assessment\nLesson Reflection",
                    tags: ["Education"], // used to filter the list of tools
                },
                {
                    name: "Syllabus GPT",
                    prompt: "Generate a syllabus based on the conversation so far.",
                    template: "Course Title\nCourse Description\nCourse Objectives\nCourse Materials\nCourse Activities\nCourse Assessment\nCourse Reflection",
                    tags: ["Education"], // used to filter the list of tools
                },
                {
                    name: "Movie Script GPT",
                    prompt: "Generate a movie script based on the conversation so far.",
                    template: "Title\nCharacters\nSetting\nPlot\nDialogue\nClimax\nResolution",
                    tags: ["Production"], // used to filter the list of tools
                },
                {
                    name: "Novel GPT",
                    prompt: "Generate a novel based on the conversation so far.",
                    template: "Title\nCharacters\nSetting\nPlot\nDialogue\nClimax\nResolution",
                    tags: ["Production"], // used to filter the list of tools
                },
                {
                    name: "Song GPT",
                    prompt: "Generate a song based on the conversation so far.",
                    template: "Title\nVerse 1\nChorus\nVerse 2\nChorus\nBridge\nChorus\nOutro",
                    tags: ["Production"], // used to filter the list of tools
                },
                {
                    name: "Financial Analysis GPT",
                    prompt: "Generate a financial analysis based on the conversation so far.",
                    template: "Income Statement\nBalance Sheet\nCash Flow Statement\nFinancial Ratios\nFinancial Analysis",
                    tags: ["Finance"], // used to filter the list of tools
                },
                {
                    name: "Financial Plan GPT",
                    prompt: "Generate a financial plan based on the conversation so far.",
                    template: "Financial Goals\nFinancial Budget\nFinancial Timeline\nFinancial Marketing\nFinancial Team\nFinancial Evaluation",
                    tags: ["Finance"], // used to filter the list of tools
                },
            ],
        };
    }

    queryGPT3 = async (prompt, cb) => {
        this.setState({ error: false })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const res = await fetch("https://us-central1-gptmaster.cloudfunctions.net/openAI/promptGPT", {
            method: "POST",
            myHeaders,
            body: JSON.stringify({
                prompt: prompt,
                type: "query",
                earthoID: this.props.idToken
            }),
        }).catch((err) => console.log(err));
        const response = await res.json();
        cb && cb(response.data);
    };

    queryChatGPT = async (prompt, cb) => {
        const { user, conversation } = this.props;
        let { messages } = this.state;
        this.setState({ waiting: true, error: false });
        if (Object.keys(messages).length === 0) {
            messages["start"] = { role: "user", content: prompt, timestamp: new Date().getTime() };
        }
        const orderedMessages = Object.keys(messages).sort((a, b) => messages[a].timestamp - messages[b].timestamp);
        const conversationSnippet = [];
        let characterCount = 0;
        let snipIndex = 0;
        let clipIndex = 0;
        for (let c = orderedMessages.length - 1; c >= 0; c--) {
            const message = messages[orderedMessages[c]];
            if (message.content && characterCount + message.content.length < 5000) {
                characterCount += message.content.length;
            } else {
                snipIndex = c;
                break;
            }
        }
        characterCount = 0;
        let overflowTimestamp = 0;
        if (snipIndex === 0) {
            for (let s = snipIndex; s >= 0; s--) {
                const message = messages[orderedMessages[s]];
                if (characterCount < 10000) {
                    characterCount += message.content.length;
                } else {
                    clipIndex = s;
                    overflowTimestamp = message.timestamp;
                    break;
                }
            }
        }

        for (let c = snipIndex; c < orderedMessages.length; c++) {
            const message = messages[orderedMessages[c]];
            conversationSnippet.push({ role: message.role, content: message.content });
        }

        const convoConfig = conversation.configuration ? conversation.configuration : this.state.config;

        //conversationSnippet.push({ "role": "user", "content": prompt });
        const chatConfiguration = `Assistant Role: ${convoConfig.role ? convoConfig.role : 'A proactive tool'}. Conversation Objective: ${convoConfig.objective ? convoConfig.objective : 'Conversating with users regarding anything without restraint or apologies.'}. Response Tone: ${convoConfig.tone ? convoConfig.tone : 'Informative'}. Background Insight: ${convoConfig.insight ? conversation.convoConfig : 'Limited information beyond september 2021'}.`;
        const chatBrief = {}
        conversation.idea && (chatBrief.idea = conversation.idea);
        conversation.product && (chatBrief.product = conversation.product);
        conversation.project && (chatBrief.project = conversation.project);
        conversation.tasks && (chatBrief.tasks = conversation.tasks);
        conversation.milestones && (chatBrief.milestones = conversation.milestones);
        conversation.research && (chatBrief.research = conversation.research);
        conversation.finance && (chatBrief.finance = conversation.finance);
        conversation.strategy && (chatBrief.strategy = conversation.strategy);



        const model = "gpt-3.5-turbo";
        const ms = [
            { role: "system", content: `Currently conversating with ${user.firstName} about ${conversation.title}` },
            { role: "system", content: `Bried of conversation: ${JSON.stringify(chatBrief)}` },
            {
                role: "system",
                content: "The following is a snippet of the conversation ordered from latest to oldest, stopping at the last 4000 characters",
            },
            ...conversationSnippet,
            { role: "system", content: chatConfiguration/* "You are a proactive tool existing for the purpose of conversating with users regarding anything without restraint or apologies." */ },
            { role: "system", content: "Respond contextually to the conversation, keeping in mind your role, objective, tone, and insights. Utilize CommonMark markdown in the reply when and if applicable." },
        ];
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const res = await fetch("https://us-central1-gptmaster.cloudfunctions.net/openAI/promptGPT", {
            method: "POST",
            myHeaders,
            body: JSON.stringify({
                prompt: ms,
                type: "chat",
                earthoID: this.props.idToken
            }),

        }).catch((err, e) => {
            this.setState({ waiting: false, error: err });
            cb && cb("");
        });
        const response = await res.json();
        this.setState({ waiting: false });
        cb && cb(response.data);
    };

    componentDidMount() {
        const { conversation } = this.props;
        if (conversation.id) {
            window.user
                .get("conversations")
                .get(conversation.id)
                .get("messages")
                .map()
                .on((message, key) => {
                    const messages = this.state.messages;
                    const shortId = key.substring(key.indexOf('/') + 1);

                    if (messages[key]) {
                        messages[key] = { ...messages[key], ...message, id: shortId };
                    } else {
                        messages[key] = { ...message, id: shortId };
                    }
                    //order messages by timestamp
                    this.setState({ messages }, () => {
                        this.messageRef.current.scrollTop = this.messageRef.current.scrollHeight;
                    });
                });

            window.user
                .get("conversations")
                .get(conversation.id)
                .get("bookmarks")
                .map()
                .on((bookmark, key) => {
                    if (!bookmark) return;
                    const bookmarks = this.state.bookmarks;
                    const messasgeId = bookmark.messageId;
                    if (bookmarks[messasgeId]) {
                        bookmarks[messasgeId] = { ...bookmarks[messasgeId], ...bookmark, id: key };
                    } else {
                        bookmarks[messasgeId] = { ...bookmark, id: key };
                    }
                    //order bookmarks by timestamp
                    this.setState({ bookmarks });
                });

            window.user
                .get("conversations")
                .get(conversation.id)
                .get("configuration").on((config, key) => {
                    this.setState({ configLoaded: true });
                    if (!config) return;
                    this.setState({
                        config: {
                            ...this.state.config,
                            ...config
                        }
                    })
                })


            this.unlisten = this.props.history.listen((location, action) => {
                const messageId = location.hash.substring(1);
                if (messageId) {
                    //scroll to message
                    const messageElement = document.getElementById(messageId);
                    if (messageElement) {
                        messageElement.scrollIntoView();
                    }
                }
            });
        }
    }

    sendMessage = async (message, role, callback) => {
        if (!message || message.length < 1) return;
        let { conversation } = this.props;
        if (conversation.id) {
            await window.user.get("conversations").get(conversation.id).get("messages").set({ content: message, timestamp: new Date().getTime(), role: role }).then();
            role == "user" && this.setState({ message: "" });
            callback && callback(message);
        }
        /* this.queryChatGPT(message, (res) => {
            console.log(res);
            window.user.get('conversations').get(conversation.id).get('messages').set({ text: res, timestamp: new Date.getTime(), "role": "assistant" });
            this.setState({ currentConversation: res });
        }); */
    };

    startConversation = async (message) => {
        const { conversation } = this.props;
        if (!conversation.id) {
            const c = await window.user.get("conversations").set({ title: "New Conversation", dateCreated: new Date().getTime(), lastUpdated: new Date().getTime() }).then();
            const key = c._["#"].toString();
            await window.user
                .get("conversations")
                .get(key)
                .get("messages")
                .set({ content: message, timestamp: new Date().getTime(), role: "user" })
                .then((m, err) => {
                    this.setState({ message: "", messages: { [m._["#"]]: { content: message, timestamp: new Date().getTime(), role: "user" } } });
                    this.queryChatGPT(message, async (res) => {
                        await window.user.get("conversations").get(key).get("messages").set({ content: res, timestamp: new Date().getTime(), role: "assistant" }).then();
                        const cId = key.substring(key.indexOf("/") + 1, key.length);
                        const conversationState = {
                            title: "",
                            idea: "",
                            product: "",
                            project: "",
                            tasks: "",
                            research: "",
                            finance: "",
                            milestones: "",
                            strategy: "",
                        };
                        this.setState({ generating: true })
                        this.queryGPT3(
                            `[Start of Conversation] user:${message}\nassistant:${res} [End of Conversation]\n\nIf present in the conversation, update this state: ${JSON.stringify(conversationState)} with less than 50 characters for each property. Must be in JSON and include title.<|endoftext|>`,
                            async (r) => {
                                let updatedState = { ...conversationState };
                                try {
                                    updatedState = JSON.parse(r);
                                } catch (e) {
                                    updatedState.title = "New Conversation";
                                }
                                updatedState.title && (await window.user.get("conversations").get(key).get("title").put(updatedState.title).then());
                                updatedState.idea && (await window.user.get("conversations").get(key).get("idea").put(updatedState.idea).then());
                                updatedState.product && (await window.user.get("conversations").get(key).get("product").put(updatedState.product).then());
                                updatedState.project && (await window.user.get("conversations").get(key).get("project").put(updatedState.project).then());
                                updatedState.tasks && (await window.user.get("conversations").get(key).get("tasks").put(updatedState.tasks).then());
                                updatedState.research && (await window.user.get("conversations").get(key).get("research").put(updatedState.research).then());
                                updatedState.finance && (await window.user.get("conversations").get(key).get("finance").put(updatedState.finance).then());
                                updatedState.milestones && (await window.user.get("conversations").get(key).get("milestones").put(updatedState.milestones).then());
                                updatedState.strategy && (await window.user.get("conversations").get(key).get("strategy").put(updatedState.strategy).then());
                                await window.user.get("conversations").get(key).get("configuration").put({
                                    ...this.state.config,
                                }).then();

                                this.props.history.push(`/conversations/${cId}`);
                            }
                        );
                    });
                });
        }
    };

    conversate = async (message) => {
        this.sendMessage(message, "user", (message, cb) => {
            this.queryChatGPT(message, (res) => this.sendMessage(res, "assistant", cb));
        });
    };

    runGPTTool = (tool, index) => {
        const { user, conversation } = this.props;
        this.setState({ generating: index, workshopOpen: false, pluginsOpen: false, configurationOpen: false }, () => {
            this.messageRef.current.scrollTop = this.messageRef.current.scrollHeight;
        });
        let { messages } = this.state;
        if (Object.keys(messages).length === 0) {
            messages["start"] = { role: "user", content: prompt, timestamp: new Date().getTime() };
        }
        const orderedMessages = Object.keys(messages).sort((a, b) => messages[a].timestamp - messages[b].timestamp);
        const conversationSnippet = [];
        let characterCount = 0;
        let snipIndex = 0;
        let clipIndex = 0;
        for (let c = orderedMessages.length - 1; c >= 0; c--) {
            const message = messages[orderedMessages[c]];
            if (characterCount + message.content.length < 5000) {
                characterCount += message.content.length;
            } else {
                snipIndex = c;
                break;
            }
        }
        characterCount = 0;
        let overflowTimestamp = 0;
        if (snipIndex === 0) {
            for (let s = snipIndex; s >= 0; s--) {
                const message = messages[orderedMessages[s]];
                if (characterCount < 10000) {
                    characterCount += message.content.length;
                } else {
                    clipIndex = s;
                    overflowTimestamp = message.timestamp;
                    break;
                }
            }
        }

        for (let c = snipIndex; c < orderedMessages.length; c++) {
            const message = messages[orderedMessages[c]];
            conversationSnippet.push(`${message.role}:${message.content}`);
        }

        this.queryGPT3(
            `[Start of Conversation] ${conversationSnippet.join("\n")}[End of Conversation].\nYou are ${tool.name}. Answer this prompt: ${tool.prompt}. This is the expected answer template ${tool.template}. Utilize CommonMark markdown<|endoftext|>`,
            async (r) => {
                if (r === false) {
                    this.setState({ generating: false, error: "API is currently experiencing high traffic, please try your request again." });
                    return;
                }
                let xr = `${tool.name} generated:\n${r}`;
                try {
                    this.sendMessage(xr, "system", () => {
                        this.setState({ generating: false, workshopOpen: false, pluginsOpen: false, configurationOpen: false, error: null });
                    })
                } catch (e) {
                    console.log("ERROR", e);

                    this.setState({ generating: false });
                }
            }
        );
    };

    toggleWorkshop = () => {
        this.setState({ workshopOpen: !this.state.workshopOpen, pluginsOpen: false, configurationOpen: false });
    };

    togglePlugins = () => {
        this.setState({ pluginsOpen: !this.state.pluginsOpen, workshopOpen: false, configurationOpen: false });
    };

    toggleConfiguration = () => {
        this.setState({ configurationOpen: !this.state.configurationOpen, workshopOpen: false, pluginsOpen: false });
    };

    bookmarkMessage = async (messageId) => {
        const { conversation } = this.props;
        const { bookmarks } = this.state;
        let bookmark = bookmarks[messageId];
        if (bookmark) {
            await window.user.get("conversations").get(conversation.id).get("bookmarks").get(bookmark.id).put(null);
            delete bookmarks[messageId];
            this.setState({ bookmarks });
        } else {

            await window.user.get("conversations").get(conversation.id).get("bookmarks").set({
                messageId,
                date: new Date().getTime()
            });
        }
    }

    render() {
        const { workshopOpen, pluginsOpen, configurationOpen, message, waiting, messages, generating, gptTools, gptTags, error } = this.state;
        const { conversation, deleteConversation, user } = this.props;
        const orderedMessages = Object.keys(messages).sort((a, b) => messages[a].timestamp - messages[b].timestamp);
        const conversationStarted = orderedMessages.length > 0;
        const allTags = {};
        const gptLibrary = gptTools.map((tool, it) => {
            let filtered = false;
            if (tool.tags) {
                tool.tags.forEach((tag) => {
                    if (gptTags[tag] && gptTags[tag] == true) {
                        filtered = true;
                    }
                    allTags[tag] = true;
                });
            }
            if (Object.keys(gptTags).length != 0 && !filtered) {
                return null;
            }
            return (<div className="workshopItem" onClick={() => { }} key={`wi-${it}`}>
                <label>{tool.name}</label>
                <p style={{ marginBottom: 3 }}>{tool.prompt}</p>
                <p className="small mb-10">{tool.template}</p>
                <div className="flex jcsb aic mt-a" style={{ height: 40 }}>
                    {/* <div className="flex">
                        <button className="mr-5 icon"><i className="fas fa-pen"></i></button>
                        <button className="icon"><i className="fas fa-code-branch"></i></button>
                    </div> */}
                    <button className={`actionFillButton ${generating !== false && it == generating ? 'loading' : ''} ${generating !== false ? 'disabled' : ''}`} style={{ minWidth: 100 }} onClick={() => {
                        this.runGPTTool(tool, it);
                    }}><div className="wave green"></div><span>{generating !== false && it == generating ? 'GENERATING' : 'GENERATE'}</span></button>

                </div>
            </div>
            )
        })
        const tagOptions = [];
        Object.keys(allTags).forEach(t => { if (!gptTags[t]) tagOptions.push({ label: t, value: t }) });
        return (
            <div className="content">
                <div className="contact-profile flex-aic">
                    <p style={{ marginLeft: 20 }}>{conversation.title}</p>
                    {conversationStarted && <div className="social-media ml-a">
                        <i className={`fa fa-diagnoses ${workshopOpen ? "green" : ""}`} aria-hidden="true" onClick={this.toggleWorkshop}></i>
                        {/* <i className={`fa fa-plug ${pluginsOpen ? "green" : ""}`} onClick={this.togglePlugins} aria-hidden="true"></i> */}
                        <i className={`fa fa-cogs ${configurationOpen ? "green" : ""}`} aria-hidden="true" onClick={this.toggleConfiguration}></i>
                    </div>}
                </div>
                <div className={`collapse-container white ${conversationStarted && workshopOpen ? "active" : ""}`}>
                    <div className="flex-aic">
                        <h3>GPT Library</h3>

                        {/* conversationStarted && (
                            <button className={`actionFillButton ${summarizing ? "loading" : ""}`} style={{ marginLeft: "auto" }} onClick={() => this.summarizeConversation()}>
                                <div className="wave green"></div>
                                <span>{summarizing ? "Analyzing" : "Analyze"}</span>
                            </button>
                        ) */}

                        {/* <button className={`actionFillButton ml-a`} onClick={() => this.setState({ addNewToolOpen: true })}>
                            <div className="wave dark"></div>
                            <span>CREATE NEW</span>
                        </button> */}
                    </div>
                    <div className="flex wrap">
                        <Select
                            classNamePrefix={"tags"}
                            className={"w-100"}

                            placeholder="GPT Tags"
                            value={{ label: "Filter", value: "" }}
                            options={tagOptions}
                            onChange={(e) => {
                                let newTags = { ...gptTags };
                                newTags[e.value] = true;
                                this.setState({ gptTags: newTags });
                            }}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    backgroundColor: '#f5f5f5',
                                }),
                            }}
                        ></Select>
                        {Object.keys(gptTags).map(t => {
                            return <div className="filterTag" onClick={() => {
                                let newTags = { ...gptTags };
                                delete newTags[t];
                                this.setState({ gptTags: newTags });
                            }}>
                                {t} <i className="fas fa-times"></i>
                            </div>
                        })}
                    </div>
                    <div className="flex wrap">
                        {gptLibrary}
                    </div>
                    {/* <div className='wrap flex-aic'>
                    <button>Portfolio</button>
                    <button>Program</button>
                    <button>Operations</button>
                    <button>Initiative</button>
                    <button>Campaign</button>
                    <button>Strategy</button>
                </div> */}
                </div>
                <div className={`collapse-container white ${conversationStarted && pluginsOpen ? "active" : ""}`}>
                    <h3>Integrations</h3>
                    <div className="wrap flex-aic">
                        <button>Monday.com</button>
                        <button>Salesforce</button>
                        <button>MailChimp</button>
                        <button>Google Drive</button>
                        <button>GMail</button>
                        <button>Trello</button>
                    </div>
                </div>
                <div className={`collapse-container white ${configurationOpen ? "active" : ""}`}>
                    {this.state.configLoaded && <ChatConfig conversation={this.state.config} conversationId={conversation.id} conversationStarted={conversationStarted} updateConversationConfig={(config) => {
                        window.user.get("conversations").get(conversation.id).get("configuration").put(config);
                    }} deleteConversation={deleteConversation} />}

                </div>
                <div className={`messages ${conversationStarted ? "" : "openingPage"}`} ref={this.messageRef} onClick={() => {
                    this.setState({ workshopOpen: false, pluginsOpen: false, configurationOpen: false })
                }}>
                    {orderedMessages.length > 0 ? (
                        <ul>
                            {orderedMessages.map((key) => {
                                const message = messages[key];
                                return (
                                    <Message
                                        role={message.role && message.role == "user" ? "sent" : "replies"}
                                        key={key}
                                        id={key}
                                        shortId={message.id}
                                        bookmarks={this.state.bookmarks}
                                        message={message}
                                        bookmarkMessage={this.bookmarkMessage}
                                        iconUrl={message.role && message.role == "user" ? user.photoURL : false}
                                        conversationId={conversation.id}
                                    />
                                );
                            })}
                            {waiting && (
                                <ChatLoader
                                    scrollToBottom={() => {
                                        this.messageRef.current.scrollTop = this.messageRef.current.scrollHeight;
                                    }}
                                />
                            )}
                            {error && error.length > 0 && (<li>{error}</li>)}
                            {generating !== false && generating === true ? (<li><button className={`actionFillButton loading`} style={{ minWidth: 200, margin: '0 auto' }}><div className="wave green"></div><span>LOADING</span></button></li>) : generating !== false && (<li><button className={`actionFillButton loading`} style={{ minWidth: 200, margin: '0 auto' }}><div className="wave green"></div><span>{gptTools[generating].name} GENERATING</span></button></li>)}
                        </ul>
                    ) : (
                        <Fragment>
                            <h1 className="title">GPT Master</h1>
                            <ul className="tutorial">
                                <li style={{ padding: 10 }}>
                                    <i className="fa fa-feather-alt" style={{ width: 35 }}></i>Talk about anything that is on your mind
                                </li>
                                <li style={{ padding: 10 }}>
                                    <i className="fa fa-seedling" style={{ width: 35 }}></i>Use the conversation to grow your ideas
                                </li>
                                <li style={{ padding: 10 }}>
                                    <i className="fa fa-diagnoses" style={{ width: 35 }}></i>Turn your ideas into work using GPT tools
                                </li>
                                <li style={{ padding: 10, margin: 0 }}>
                                    <i className="fa fa-plug" style={{ width: 35 }}></i>Execute your work through integrations
                                </li>

                                <li style={{ padding: '0 10px', margin: 0 }}>

                                    <div className="configurationInput" style={{ textAlign: 'center', width: '100%' }}>

                                        <label className="mb-5">Conversation with</label>
                                        <input style={{ textAlign: 'center', padding: 5 }} value={this.state.config.role} onChange={(e) => {
                                            let newConfig = { ...this.state.config };
                                            newConfig.role = e.target.value;
                                            this.setState({ config: newConfig });
                                        }}>
                                        </input>
                                    </div>
                                </li>
                            </ul>
                        </Fragment>
                    )}
                </div>
                <ChatInput waiting={waiting} conversationStarted={conversationStarted} conversate={this.conversate} startConversation={this.startConversation} untoggleTopMenu={() => {
                    this.setState({ workshopOpen: false, pluginsOpen: false, configurationOpen: false })
                }} />
                {/* <div className="message-input">
                    <div className="wrap input-container">
                        <textarea
                            type="text"
                            placeholder="Write your message..."
                            value={message}
                            onChange={(event) => {
                                this.setState({ message: event.target.value });
                            }}
                            onKeyUp={(e) => {
                                //Enter key
                                if (e.key === "Enter" && !e.shiftKey) {
                                    if (conversationStarted) this.conversate();
                                    else this.startConversation();
                                }
                            }}

                            onKeyDown={(e) => {
                                //Enter key
                                if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                }
                            }}
                            style={{ height: 40 + 20 * messageBreaks }}
                        />
                        <i className="fa fa-paperclip attachment" aria-hidden="true"></i>
                        <button disabled={waiting} onClick={conversationStarted ? this.conversate : this.startConversation} className="submit">
                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                    </div>
                </div> */}
            </div >
        );
    }
}

export default withRouter(ChatRoom);
