import React from "react";

class Contact extends React.Component {
    render() {
        const { active, user, onClick, title, preview, date } = this.props;
        return (

            <li className={`contact ${active ? 'active' : ''}`}>
                <div className="wrap">
                    {/* <span className="contact-status online"></span> */}
                    <div className="circle">{date} </div>
                    <div className="meta">
                        <p className="name">{title}</p>
                        <p className="preview">{preview}</p>
                    </div>
                </div>
            </li>
        );
    }
}

export default Contact;