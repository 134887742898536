import React, { Component } from "react";
import "../assets/css/chat.scss";
import Contact from "./Contact";
import Message from "./Message";
import moment from "moment/moment";
import ChatRoom from "./ChatRoom";
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Fragment } from "react";
import WorkRoom from "./WorkRoom";

class ChatInterface extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountOpen: false,
            workOpen: false,
            message: "",
            currentConversation: null,
            conversations: [],
            work: [],
            waiting: false,
            search: "",
        };
    }

    componentDidMount = async () => {
        const { user } = this.props;
        if (window.user && window.user.is) {
            window.user
                .get("conversations")
                .map()
                .on((data, key) => {
                    if (data && key && key != "undefined" && key != "null") {
                        const conversationIndex = this.state.conversations.findIndex((c) => c.id === key);
                        if (conversationIndex > -1) {
                            const conversations = this.state.conversations;
                            conversations[conversationIndex] = { ...data, id: key };
                            this.setState({ conversations });
                        } else {
                            const conversations = this.state.conversations;
                            conversations.push({ ...data, id: key });
                            this.setState({ conversations });
                        }
                    }
                });
            /* window.user.get('work').on((data, err) => {
                if (err) {
                    console.log(err);
                }
                else {
                    console.log(data);
                    const work = data.val();
                    this.setState({ work });
                }
            }); */
        } else if (user && window.user) {
            try {
                window.user.create(user.uid, user.uid, async (res) => {
                    if (res.err) {
                        if (res.err == "User already created!") {
                            //window.location.reload(true);
                            window.user.auth(user.uid, user.uid, this.componentDidMount);
                        }
                    } else if (res.pub) {
                        window.user.auth(user.uid, user.uid, this.componentDidMount);
                    }
                });
            } catch (err) {
                console.log(err);
            }
        }
    }

    createConversation = async (message, cb) => {
        if (!message || message.length < 1) return;
        const conversation = await window.user.get("conversations").set({ title: "New Conversation", dateCreated: new Date().getTime() });
        await conversation.get("messages").set({ content: message, timestamp: new Date().getTime() }).then();
        this.props.history.push(`/conversation/${conversation}`);
    };

    sendMessage = async (message, cb) => {
        if (!message || message.length < 1) return;
        const conversation = await window.user.get("conversations").set({ title: "New Conversation", dateCreated: new Date().getTime() }).then();
        const key = conversation._["#"];
        await window.user
            .get("conversations")
            .get(key.toString())
            .get("messages")
            .set({ content: message, timestamp: new Date().getTime(), role: "user" })
            .then((m, err) => {
                this.props.history.push(`/conversations/${key}`);
            });
        /* this.props.queryChatGPT(message, (res) => {
            console.log(res);
            window.user.get('conversations').get(conversation).get('messages').set({content: res, timestamp: new Date.getTime()});
            this.setState({ currentConversation: res });
        }); */
    };

    deleteConversation = (conversationId) => {
        const { conversations } = this.state;
        window.user.get("conversations").get(conversationId).put(null);
        const conversationIndex = conversations.findIndex((c) => c.id == conversationId);
        if (conversationIndex > -1) {
            conversations.splice(conversationIndex, 1);
            this.setState({ conversations });
            this.props.history.push("/");
        }
    };

    render() {
        const { user } = this.props;
        const { workOpen, accountOpen, conversations, search } = this.state;
        return (
            <div id="frame">
                <div id="sidepanel" className={`${workOpen ? 'open' : ''}`}>
                    <div id="top-bar">
                        <button
                            id="conversationButton"
                            className={`${!workOpen ? "selected" : ""}`}
                            onClick={() => {
                                this.setState({ workOpen: false });
                            }}
                        >
                            <i className="fas fa-stream"></i>
                            <span>Conversations</span>
                        </button>
                        <button
                            id="contextButton"
                            className={`flex aic jcc ${workOpen ? "selected" : ""}`}
                            onClick={() => {
                                this.setState({ workOpen: true, });
                            }}
                        >
                            <span>
                                Context
                            </span>
                            <svg className="sparkle" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                                    fill="black"
                                    stroke="black"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                                    fill="black"
                                    stroke="black"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                                    fill="black"
                                    stroke="black"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>

                    {!workOpen && (
                        <Fragment>
                            <div id="search" className="conversation">
                                <label >
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </label>
                                <input type="text" placeholder="Search..." value={search} onChange={e => {
                                    this.setState({ search: e.target.value });
                                }} />
                            </div>
                            <div id="contacts" style={{ marginBottom: 20 }} className={` ${accountOpen ? "accountOpen" : ""} `}>
                                <ul>
                                    {conversations
                                        .sort((a, b) => moment(a.dateCreated).isAfter(moment(b.dateCreated)) ? -1 : 1)
                                        .map((c) => {
                                            if (c.id == "undefined" || c.id == undefined) return null;
                                            if (search != '' && c.title && !c.title.toLowerCase().includes(search.toLowerCase())) return null;
                                            const cId = c.id.substring(c.id.indexOf("/") + 1, c.id.length);
                                            return (
                                                <NavLink to={`/conversations/${cId}`} key={cId} className={isActive =>
                                                    `conversationLink ${isActive ? "active" : ""}`
                                                }>
                                                    <Contact title={c.title} preview={c.preview || ""} date={moment(c.dateCreated).format("MMM D")} />
                                                </NavLink>
                                            );
                                        })}
                                </ul>
                            </div>
                        </Fragment>
                    )}
                    <Switch>
                        <Route exact path={`/`} render={(props) => <Fragment>{workOpen && <WorkRoom accountOpen={accountOpen} conversation={null} idToken={this.props.idToken} />}</Fragment>} />
                        {conversations.map((conversation, index) => {
                            if (conversation.id == "undefined" || conversation.id == undefined) return null;

                            const cId = conversation.id.substring(conversation.id.indexOf("/") + 1, conversation.id.length);
                            return (
                                <Route
                                    key={cId}
                                    path={`/conversations/${cId}`}
                                    render={(props) => {
                                        return <Fragment>{workOpen && <WorkRoom accountOpen={accountOpen} conversation={conversation} idToken={this.props.idToken} />}</Fragment>;
                                    }}
                                />
                            );
                        })}
                    </Switch>
                    <div id="profile" className="flex-aic">
                        <div className="wrap flex-aic">
                            <img id="profile-img" src={user.photoURL} className="online" alt="" />
                            <p>
                                {user.firstName} {user.lastName}
                                <br />
                                <span className="small">{user.email}</span>
                            </p>
                        </div>
                        <i className="fa fa-power-off c-point"
                            onClick={async () => {
                                await this.props.logout();
                                window.location.href = "/";
                            }}
                            style={{ marginLeft: "auto" }}></i>
                    </div>
                    <div id="bottom-bar">
                        <Link to="/">
                            <button id="addcontact">
                                <i className="fa fa-plus-circle fa-fw" aria-hidden="true"></i> <span>New Conversation</span>
                            </button>
                        </Link>
                        <button onClick={() => this.setState({ accountOpen: !this.state.accountOpen })} id="settings" className={`${accountOpen ? "selected" : ""}  `}>
                            <i className="fa fa-user-cog fa-fw" aria-hidden="true"></i> <span>Account</span>
                        </button>
                    </div>
                </div>

                <Switch>
                    {/* <Route exact path="/">
                    <div className="content">
                        <div className="contact-profile flex-aic">
                            <p style={{ marginLeft: 20 }}>Start a Conversation</p>
                            <div className="social-media ml-a">
                                <i className={`fa fa-diagnoses ${workshopOpen ? 'green' : ''}`} aria-hidden="true" onClick={() => this.setState({ workshopOpen: !this.state.workshopOpen })}></i>
                                <i className={`fa fa-plug ${pluginsOpen ? 'green' : ''}`} onClick={() => this.setState({ pluginsOpen: !this.state.pluginsOpen })} aria-hidden="true"></i>
                                <i className={`fa fa-cogs ${configurationOpen ? 'green' : ''}`} aria-hidden="true" onClick={() => this.setState({ configurationOpen: !this.state.configurationOpen })}></i>
                            </div>
                        </div>
                        <div className={`collapse-container white ${workshopOpen ? 'active' : ''}`}>
                            <h3>Workshop</h3>
                            <div className='wrap flex-aic'>
                                <button>Product</button>
                                <button>Project</button>
                                <button>Task</button>
                                <button>Research</button>
                                <button>Finance</button>
                                <button>Strategy</button>
                            </div>
                        </div>
                        <div className={`collapse-container white ${pluginsOpen ? 'active' : ''}`}>
                            <h3>Plugins</h3>
                            <div className='wrap flex-aic'>
                            </div>
                        </div>
                        <div className={`collapse-container white ${configurationOpen ? 'active' : ''}`}>
                            <h3>Configuration</h3>
                            <button>Delete</button>
                        </div>
                        <div className="messages flex-aic" style={{ justifyContent: 'center', flexDirection: 'column' }}>
                            <h1>GPT Master</h1>
                            <ul className='tutorial'>
                                <li><i className='fa fa-feather-alt' style={{ width: 35 }}></i>Talk about anything that is on your mind</li>
                                <li><i className='fa fa-seedling' style={{ width: 35 }}></i>Use the conversation to grow your ideas</li>
                                <li><i className='fa fa-diagnoses' style={{ width: 35 }}></i>Turn your ideas into work using GPT tools</li>
                                <li><i className='fa fa-plug' style={{ width: 35 }}></i>Execute your work through integrations</li>
                            </ul>
                        </div>
                        <div className="message-input">
                            <div className="wrap input-container">
                                <input type="text" placeholder="Write your message..." value={message} onChange={(event) => {
                                    this.setState({ message: event.target.value })
                                }} />
                                <i className="fa fa-paperclip attachment" aria-hidden="true"></i>
                                <button disabled={waiting} onClick={(e) => this.sendMessage(this.state.message)} className="submit"><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </Route> */}
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <ChatRoom {...props} conversation={{ title: "Start a Conversation" }} idToken={this.props.idToken} deleteConversation={this.deleteConversation} user={user} hideTools={true} />
                        )}
                    />
                    {conversations
                        .map((conversation, index) => {
                            if (conversation.id == "undefined" || conversation.id == undefined) return null;
                            const cId = conversation.id.substring(conversation.id.indexOf("/") + 1, conversation.id.length);
                            return (
                                <Route
                                    key={cId}
                                    path={`/conversations/${cId}`}
                                    render={(props) => <ChatRoom {...props} idToken={this.props.idToken} conversation={conversation} deleteConversation={this.deleteConversation} user={user} />}
                                />
                            );
                        })}
                </Switch>
            </div>
        );
    }
}

export default withRouter(ChatInterface);
