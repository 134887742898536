import React from "react";
import "../assets/css/whirlwind.scss";

class Whirlwind extends React.Component {

    render() {
        return <div className="whirlwind">
            <div className='wrap'>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
                <div className='c'></div>
            </div>

        </div>
    }
}

export default Whirlwind;