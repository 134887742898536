import React from "react";

class File extends React.Component {
    render() {
        const { active, preview, date, title } = this.props;
        return (

            <div className={`bookmark ${active ? 'active' : ''}`}>
                <div className="wrap">
                    {/* <span className="contact-status online"></span> */}
                    <div className="meta">
                        <p className="name">{title}</p>
                        <p className="preview" >{preview.length > 100 ? `${preview.substring(0, 100).trim()}...` : preview}</p>
                    </div>
                </div>
                <i style={{ fontSize: 10, position: 'absolute', bottom: 5, right: 5 }} className="fa fa-arrow-right attachment"></i>
            </div>
        );
    }
}

export default File;