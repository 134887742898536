import { useEarthoOne } from "@eartho/one-client-react";
import Dashboard from "./Pages/Dashboard";
import "./App.scss";
import { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Whirlwind from "./Components/Whirlwind";
import Landing from "./Pages/Landing";
import { useState } from "react";
import { useEffect } from "react";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";

const oneMonthSubscriptionAccess = "gzlSUazDS76AbG48rXa7";
const oneWeekSubscriptionAccess = "";
const threeDayTrialAccess = "bGqJpobHcArWryBYlUMk";
const oneDayPass = "yrWHrLLAURuBpczquvWl";
const oneDayTrialAccess = "VsCPm28jD1j6HU9cwGbn";
const daySubscriptionAcess = "CZhrPJhZsFsL8NBKGFqI";
const devAccess = "WNefMPBssyrzxypaq8TS";

export default function App() {
  const [learnMoreOpen, setLearnMoreOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [accessId, setAccessId] = useState(oneMonthSubscriptionAccess); // process.env.REACT_APP_ACCESS_ID
  const { isLoading, isConnected, connectWithPopup } = useEarthoOne();
  const [upgradeAvailable, setUpgradeAvailable] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const invite = urlParams.get("invite");
    if (invite && invite == "FRIEND") {
      localStorage.setItem("access_id", threeDayTrialAccess);
      localStorage.setItem("access_date", new Date().getTime());
    } else if (invite && invite == "USER") {
      localStorage.setItem("access_id", oneDayTrialAccess);
      localStorage.setItem("access_date", new Date().getTime());
    } else if (invite && invite == "WILLDEV") {
      localStorage.setItem("access_id", devAccess);
      localStorage.setItem("access_date", new Date().getTime());
    }
    if (localStorage.getItem("access_id")) {
      const accessDate = localStorage.getItem("access_date");
      const accessType = localStorage.getItem("access_id");
      let accessId = localStorage.getItem("access_id");
      if (accessType == threeDayTrialAccess) {
        if (new Date().getTime() - accessDate > 3 * 24 * 60 * 60 * 1000) {
          localStorage.removeItem("access_id");
          localStorage.removeItem("access_date");
          accessId = daySubscriptionAcess;
        }
      } else if (accessType == oneDayTrialAccess) {
        if (new Date().getTime() - accessDate > 1 * 24 * 60 * 60 * 1000) {
          localStorage.removeItem("access_id");
          localStorage.removeItem("access_date");
          accessId = daySubscriptionAcess;
        }
      }
      setAccessId(accessId);

    }


    window.addEventListener("message", (e) => {
      if (e.data && e.data === 'swUpdated') {
        setUpgradeAvailable(true);
      }
    });
  }, []);

  return (
    <Router>
      <div className="container">
        {
          <Fragment>
            {isConnected ? (
              <Dashboard />
            ) : (
              <div className="flex-container">
                <Whirlwind />
                <div className="actionFillButton" style={{ position: "absolute", top: 20, left: 20 }} onClick={() => {
                  upgradeAvailable && window.upgrade();
                }}>
                  <div className="wave green"></div>
                  <span>BETA v1.03{upgradeAvailable ? '^' : ''}</span>
                </div>
                <div className="flex aic jcsb ph-20 mobileCol reverse" style={{ position: "absolute", bottom: 20, width: "100%" }}>
                  <div>
                    <label style={{ fontSize: 10 }}>
                      <a href="#" onClick={() => setTermsOpen(true)}>Terms of use</a>
                      <span className="ml-5 mr-5">|</span>
                      <a href="#" onClick={() => setPrivacyOpen(true)}>Privacy policy</a>
                    </label>
                  </div>
                  <div>
                    <div className="actionFillButton" onClick={() => window.open('https://openai.com', '_blank')}>
                      <div className="wave dark"></div>
                      <span>Powered by OpenAI</span>
                    </div>
                    <h4 style={{ transform: "scale(.7)" }}>Built by AI Industry Experts</h4>
                  </div>
                </div>
                <div className="flex col aic" style={{ position: "relative" }}>
                  <h1 className="title">GPT Master</h1>
                  <h2 className="mb-10 text-gradient">Evolve Your Conversations</h2>
                  <div className="flex aic mt-20">
                    <button className={` mr-10 actionFillButton `} onClick={() => setLearnMoreOpen(true)}>
                      <div className="wave dark"></div>
                      <span>LEARN MORE</span>
                    </button>
                    <button
                      className={` ml-10 actionFillButton ${isLoading ? "loading" : ""} ${isLoading != false ? "disabled" : ""}`}
                      onClick={() => connectWithPopup({ access_id: accessId })}
                    >
                      <div className="wave green"></div>
                      <span>{isLoading ? "CONNECTING" : "CONNECT"} {accessId == threeDayTrialAccess || accessId == oneDayTrialAccess ? '*' : ''}</span>
                    </button>
                  </div>
                </div>
                <Landing open={learnMoreOpen} toggle={() => setLearnMoreOpen(false)} connect={() => connectWithPopup({ access_id: accessId })} />
                <Terms open={termsOpen} toggle={() => {
                  setTermsOpen(false);
                }} />
                <Privacy open={privacyOpen} toggle={() => {
                  setPrivacyOpen(false);
                }} />
              </div>
            )}
          </Fragment>
        }
      </div>
    </Router>
  );
}
