import React from "react";
import { Fragment } from "react";
import File from "./File";
import moment from "moment";
import { Link } from "react-router-dom";
const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
    apiKey: "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",
});


class WorkRoom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: {},
            summarizing: false,
            lastReviewed: '',
            tabSelected: 'brief',
            bookmarks: {},
            bookmarkSelected: '',
            note: '',
            notes: {},
        }
    }

    componentDidMount() {
        const { conversation } = this.props;
        if (conversation && conversation.id) {
            window.user
                .get("conversations")
                .get(conversation.id)
                .get("messages")
                .map()
                .on((message, key) => {
                    const messages = this.state.messages;
                    //const files = this.state.files;
                    if (messages[key]) {
                        messages[key] = { ...messages[key], ...message };
                    } else {
                        messages[key] = message;
                    }

                    /* if (message.isFile) {
                        if (files[key]) {
                            files[key] = { ...files[key], ...message };
                        } else {
                            files[key] = message;
                        }
                    } */
                    //order messages by timestamp
                    this.setState({ messages });
                });
            window.user
                .get("conversations")
                .get(conversation.id)
                .get("notes")
                .map()
                .on((note, key) => {
                    const notes = this.state.notes;
                    if (notes[key]) {
                        notes[key] = { ...notes[key], ...note };
                    } else {
                        notes[key] = note;
                    }
                    //order notes by timestamp
                    this.setState({ notes });
                });

            window.user
                .get("conversations")
                .get(conversation.id)
                .get("bookmarks")
                .map()
                .on((bookmark, key) => {
                    const bookmarks = this.state.bookmarks;
                    if (!bookmark) {
                        delete bookmarks[key];
                    } else if (bookmarks[key]) {
                        bookmarks[key] = { ...bookmarks[key], ...bookmark };
                    } else {
                        bookmarks[key] = bookmark;
                    }
                    //order bookmarks by timestamp
                    this.setState({ bookmarks });
                });

            //get last reviewed
            window.user
                .get("conversations")
                .get(conversation.id)
                .get("lastReviewed")
                .on((lastReviewed) => {
                    this.setState({ lastReviewed });
                }
                );
        }
    }

    queryGPT3 = async (prompt, cb) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const res = await fetch("https://us-central1-gptmaster.cloudfunctions.net/openAI/promptGPT", {
            method: "POST",
            myHeaders,
            body: JSON.stringify({
                prompt: prompt,
                type: "query",
                earthoID: this.props.idToken
            }),
        }).catch((err) => console.log(err));
        const response = await res.json();
        cb && cb(response.data);
    };


    summarizeConversation = () => {
        const { user, conversation } = this.props;
        let { messages } = this.state;
        if (Object.keys(messages).length === 0) {
            messages["start"] = { role: "user", content: prompt, timestamp: new Date().getTime() };
        }
        this.setState({ summarizing: true });
        const orderedMessages = Object.keys(messages).sort((a, b) => messages[a].timestamp - messages[b].timestamp);
        const openai = new OpenAIApi(configuration);
        const conversationSnippet = [];
        let characterCount = 0;
        let snipIndex = 0;
        let clipIndex = 0;
        for (let c = orderedMessages.length - 1; c >= 0; c--) {
            const message = messages[orderedMessages[c]];
            if (characterCount + message.content.length < 5000) {
                characterCount += message.content.length;
            } else {
                snipIndex = c;
                break;
            }
        }
        characterCount = 0;
        let overflowTimestamp = 0;
        if (snipIndex === 0) {
            for (let s = snipIndex; s >= 0; s--) {
                const message = messages[orderedMessages[s]];
                if (characterCount < 10000) {
                    characterCount += message.content.length;
                } else {
                    clipIndex = s;
                    overflowTimestamp = message.timestamp;
                    break;
                }
            }
        }

        for (let c = snipIndex; c < orderedMessages.length; c++) {
            const message = messages[orderedMessages[c]];
            conversationSnippet.push(`${message.role}:${message.content}`);
        }

        const conversationState = {
            title: conversation.title || "",
            idea: conversation.idea || "",
            product: conversation.product || "",
            project: conversation.project || "",
            tasks: conversation.tasks || "",
            milestones: conversation.milestones || "",
            research: conversation.research || "",
            finance: conversation.finance || "",
            strategy: conversation.strategy || "",
        };

        this.queryGPT3(
            `[Start of Conversation]: ${conversationSnippet.join("\n")}[End of Conversation]\n\nIf present in the conversation, update this state: ${JSON.stringify(conversationState)} with less than 500 characters for each property.<|endoftext|>`,
            async (r) => {
                let xr = r;
                try {
                    const sum = JSON.parse(xr);
                    sum.title && (await window.user.get("conversations").get(conversation.id).get("title").put(sum.title).then());
                    sum.idea && (await window.user.get("conversations").get(conversation.id).get("idea").put(sum.idea).then());
                    sum.product && (await window.user.get("conversations").get(conversation.id).get("product").put(sum.product).then());
                    sum.project && (await window.user.get("conversations").get(conversation.id).get("project").put(sum.project).then());
                    sum.tasks && (await window.user.get("conversations").get(conversation.id).get("tasks").put(sum.tasks).then());
                    sum.milestones && (await window.user.get("conversations").get(conversation.id).get("tasks").put(sum.milestones).then());
                    sum.research && (await window.user.get("conversations").get(conversation.id).get("research").put(sum.research).then());
                    sum.finance && (await window.user.get("conversations").get(conversation.id).get("finance").put(sum.finance).then());
                    sum.strategy && (await window.user.get("conversations").get(conversation.id).get("strategy").put(sum.strategy).then());
                    const lastReviewed = new Date().getTime();
                    await window.user.get("conversations").get(conversation.id).get("lastReviewed").put(lastReviewed);

                    this.setState({ summarizing: false });
                } catch (e) {
                    console.log("ERROR", e);
                    this.setState({ summarizing: false });
                }
            }
        );
    };

    saveNote = async () => {
        const { conversation } = this.props;
        const { note } = this.state;
        await window.user.get("conversations").get(conversation.id).get("notes").set({
            date: new Date().getTime(),
            content: note,

        });
        this.setState({ noteSaved: true, note: "" });
    }

    deleteNote = async (noteId) => {
        const { conversation } = this.props;
        await window.user.get("conversations").get(conversation.id).get("notes").get(noteId).put(null);
        const notes = this.state.notes;
        delete notes[noteId];
        this.setState({ notes });
    }

    render() {
        const { conversation, accountOpen } = this.props;
        const { tabSelected, summarizing, bookmarks, bookmarkSelected, note, notes, messages, lastReviewed } = this.state;
        const lastReviewedDate = lastReviewed != '' ? moment(conversation.lastReviewed).format("MMM Do YYYY") : "New"
        const lastReviewedTime = lastReviewed != '' ? moment(conversation.lastReviewed).format("@ h:mm a") : "";
        const dateCreated = conversation && moment(conversation.dateCreated).format("MMM Do YYYY");
        const timeCreated = conversation && moment(conversation.dateCreated).format("@ h:mm a");
        return <Fragment>

            <div id="search">
                <div className="flex-aic" style={{ justifyContent: 'space-around', height: 40, width: '100%' }}>

                    <button
                        className={`tab ${tabSelected == 'brief' ? 'selected' : ''}`}
                        onClick={() => this.setState({ tabSelected: 'brief' })}
                    ><i className="fas fa-scroll mr-10"></i>Brief</button>
                    <button
                        className={`tab ${tabSelected == 'tasks' ? 'selected' : ''}`}
                        onClick={() => this.setState({ tabSelected: 'tasks' })}
                    ><i className="fas fa-sticky-note mr-10"></i>Notes</button>
                    <button
                        className={`tab ${tabSelected == 'jobs' ? 'selected' : ''}`}
                        onClick={() => this.setState({ tabSelected: 'jobs' })}
                    > <i className="fas fa-bookmark mr-10"></i>Bookmarks</button>
                </div>
            </div>
            <div id="contacts" className={`workArea ${accountOpen ? 'accountOpen' : ''}  `} >
                {<div className={`workSpace ${tabSelected == 'brief' ? 'open' : ''}`}>
                    <div className="flex jcsb">
                        {conversation && conversation.messages && (lastReviewed == '' ? <div>
                            <label className="titleLabel">Started On</label>
                            <h3 className="mb-10">{dateCreated}<span style={{ fontSize: '70%', marginLeft: 5 }}>{timeCreated}</span></h3>
                        </div> : <div>
                            <label className="titleLabel">Last Reviewed</label>
                            <h3 className="mb-10">{lastReviewedDate}<span style={{ fontSize: '70%', marginLeft: 5 }}>{lastReviewedTime}</span></h3>
                        </div>)}
                        {conversation && <button className={`actionFillButton mt-10 ${summarizing ? 'loading' : ''}`} style={{ marginLeft: 'auto' }} onClick={() => this.summarizeConversation()}>
                            <div className="wave green"></div>
                            <span>{summarizing ? 'Reviewing' : 'Review'}</span>
                        </button>}
                    </div>
                    {conversation ? <div className="briefContainer">

                        {conversation.idea && <div className="mb-10">
                            <h3>Idea</h3>
                            <p>{conversation.idea}</p>
                        </div>}
                        {conversation.product && <div className="mb-10">
                            <h3>Product</h3>
                            <p>{conversation.product}</p>
                        </div>}
                        {conversation.project && <div className="mb-10">
                            <h3>Project</h3>
                            <p>{conversation.project}</p>
                        </div>}
                        {conversation.tasks && <div className="mb-10">
                            <h3>Tasks</h3>
                            <p>{conversation.tasks}</p>
                        </div>}
                        {conversation.milestones && <div className="mb-10">
                            <h3>Milestones</h3>
                            <p>{conversation.milestones}</p>
                        </div>}
                        {conversation.research && <div className="mb-10">
                            <h3>Research</h3>
                            <p>{conversation.research}</p>
                        </div>}
                        {conversation.finance && <div className="mb-10">
                            <h3>Finance</h3>
                            <p>{conversation.finance}</p>
                        </div>}
                        {conversation.strategy && <div className="mb-10">
                            <h3>Strategy</h3>
                            <p>{conversation.strategy}</p>
                        </div>}
                    </div> : <div className="flex aic jcc col h-100"><h2 className="mb-10">Conversation Brief</h2>
                        <h4 className="mb-20" style={{ textAlign: 'center' }}>Review conversations to identify key points.</h4>

                    </div>}
                </div>}
                {<div className={`workSpace ${tabSelected == 'tasks' ? 'open' : ''}`}>
                    {conversation ? <Fragment>

                        <div style={{ position: 'relative' }} className="flex col">
                            <textarea value={note}
                                placeholder="Save for later..."
                                style={{ height: 100 }}
                                onChange={(e) => this.setState({ note: e.target.value })}

                                onKeyUp={(e) => {
                                    //Enter key
                                    if (e.key === "Enter" && !e.shiftKey && note != '') {
                                        this.saveNote();
                                    }
                                }}

                                onKeyDown={(e) => {
                                    //Enter key
                                    if (e.key === "Enter" && !e.shiftKey) {
                                        e.preventDefault();
                                    }
                                }}
                                className="noteArea"
                            ></textarea>
                            <i className="fas fa-download" style={{ position: 'absolute', right: 10, bottom: 10, fontSize: 10 }}
                                onClick={() => this.saveNote()}
                            ></i>
                        </div>
                        <label className="mt-10">Saved Notes</label>
                        <div className="notesContainer">
                            {Object.keys(notes).map(f => {
                                return notes[f] && <div className="noteContainer">
                                    <p className="mb-10">{notes[f].content}</p>
                                    <div className="flex jcsb aic ">
                                        <label className="gray font75">{moment(notes[f].date).format("MM/DD/YY hh:mm:ss a")}</label>
                                        <i className="fas fa-trash op50 font75" onClick={() => this.deleteNote(f)}></i>
                                    </div>
                                </div>
                            })}

                        </div>
                    </Fragment> : <div className="flex aic jcc col h-100"><h2 className="mb-10">Keep Track</h2>
                        <h4 className="mb-20" style={{ textAlign: 'center' }}>Note down anything you want to reference later</h4>
                    </div>}
                </div>}
                {<div className={`workSpace ${tabSelected == 'jobs' ? 'open' : ''}`}>
                    {bookmarkSelected == '' ? <div className="h-100">{Object.keys(bookmarks).length > 0 ? Object.keys(bookmarks).map(f => {
                        if (!bookmarks[f]) return null;
                        const message = messages[bookmarks[f].messageId];
                        if (!message) return null;
                        const mId = bookmarks[f].messageId.substring(bookmarks[f].messageId.indexOf('/') + 1);
                        return bookmarks[f] && <Link to={`#${mId}`}><File preview={message.content} /></Link>
                    }) : <div className="flex aic jcc col h-100"><h2 className="mb-10">Bookmark</h2>
                        <h4 className="mb-20" style={{ textAlign: 'center' }}>Save to reference later in the conversation</h4>
                        <div className="flex aic">

                            <i style={{ fontSize: 20 }} className="fa fa-bookmark attachment mr-15"></i>
                            <i style={{ fontSize: 15 }} className="fa fa-arrow-right attachment"></i>
                            <i style={{ fontSize: 20 }} className="fa fa-paperclip attachment ml-15"></i>
                        </div>
                    </div>}</div> : <div><h2>{bookmarks[bookmarkSelected].title}</h2></div>}
                </div>}

            </div></Fragment>
    }
}

export default WorkRoom;