import React from "react";
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm'
import { gunAvatar } from 'gun-avatar'
const pub =
    "ZVJ5HhDyPeoswiWMzXhKxS7xNui0n2scFe5B9DZoH1Q.Jf02_OoumaGrrr1qNirCYw4i4h4zM8eLvF5PjfG0bNI";
class Message extends React.Component {

    saveBookmark = async (messageId) => {
        const { conversationId } = this.props;
        await window.user.get("conversations").get(conversationId).get("bookmarks").set({
            date: new Date().getTime(),
            messageId,
            title: ""
        });
        this.setState({ bookmarkSaved: true });
    }

    deleteBookmark = async (bookmarkId) => {
        const { conversationId } = this.props;
        await window.user.get("conversations").get(conversationId).get("bookmarks").get(bookmarkId).put(null);
        const bookmarks = this.state.bookmarks;
        delete bookmarks[bookmarkId];
        this.setState({ bookmarks });
    }

    render() {
        const { role, message, iconUrl, bookmarkMessage, id, bookmarks, shortId } = this.props;
        const bookMarked = bookmarks[id];
        const con = pub;//conversationId.substring(1, 88);
        return (
            <li id={shortId} className={role} style={{ position: 'relative' }}>
                <div className="message-container">

                    {iconUrl ? <img src={iconUrl} alt="" /> : <div className="circle"><img src={gunAvatar({ pub: con, size: 200, draw: 'squares' })} /></div>}
                    {role == "user" ? <p>{message.content}</p> : <div className="richContainer"><ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        children={message.content}
                        components={{
                            code({ node, inline, className, children, ...props }) {
                                const match = /language-(\w+)/.exec(className || '')
                                return !inline && match ? (
                                    <SyntaxHighlighter
                                        {...props}
                                        children={String(children).replace(/\n$/, '')}
                                        style={materialDark}
                                        language={match[1]}
                                        PreTag="div"
                                    />
                                ) : (
                                    <code {...props} className={className}>
                                        {children}
                                    </code>
                                )
                            }
                        }} /></div>}
                </div>

                <div className="flex col font75 ml-10" style={{ height: 'fit-content', marginTop: -10 }}>
                    <i className={`fas fa-bookmark mAction ${bookMarked ? 'green' : ''}`} onClick={() => {
                        bookmarkMessage(id);
                    }}></i>
                    <i className="fas fa-copy mAction" onClick={() => {
                        //copy to clipboard
                        navigator.clipboard.writeText(message.content);
                        alert("Copied to clipboard");
                    }}></i>
                    {/* <i className="fas fa-trash mAction" ></i> */}
                </div>
            </li>
        );
    }
}

export default Message;